<template>
    <div class="right-side">
        <h2>{{$t("AccountPage.AccountDocuments.title")}}</h2>
        <div class="mb-4">{{$t("AccountPage.AccountDocuments.description")}}</div>

        <form class="form-inline mb-5">
            <div class="form-group">
                <!-- <label for="file"> {{ $t("mainPage.choose") }} </label> -->
                <input type="file" class="form-control-file" id="file" ref="file" v-on:change="handleFileUpload()" />
            </div>
            <button class="btn btn-zhako" v-on:click="onSubmitFile()" :disabled="spinnerVisible">
                <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" v-show="spinnerVisible"></span>
                {{ $t("files.upload_file") }}
            </button>
        </form>
        
        <table-view
            :columns="myColumns"
            :data="fileList"
            :startWith="startWith"
            :selectItem="openFile"
            :onRemove="onRemove"
        >
            <template v-slot:head>
                <tr>
                    <th>№</th>
                    <th>{{$t("files.filename")}}</th>
                    <th>{{$t("files.size")}}</th>
                    <th>{{$t("files.upload_date")}}</th>
                    <th></th>
                </tr>
            </template>
            <template v-slot:last-column></template>
        </table-view>
    </div>
</template>

<script>

import TableView from '@/components/ui/TableViewDocument.vue'

export default {
    components: {
        TableView
    },
    data: () => ({
        spinnerVisible: false,
        file: undefined,
        files: [],
        fileList: [],
        startWith: 1,
        myColumns: {
            'index': '#', 
            'filename': el => el.fileDescriptionId.filename,
            'size': el => el.fileDescriptionId.filesize,
            'date': el => el.fileDescriptionId.entryDate
        },
    }),
    mounted() {
        this.onShow();
    },
    methods: {
        onShow() {
            this.getFileList();
        },
        handleFileUpload() {
            if (this.$refs.file.files.length) {
                this.file = this.$refs.file.files[0];
            }
        },
        onSubmitFile() {
            this.spinnerVisible = true;
            this.$store.dispatch("UPLOAD_DOCUMENT", {
                file: this.file, 
                callback: this.callback
            })
            .then(s => {
                s.data && this.files.push({fileDescriptionId: s.data});
                this.file = undefined;
                
                this.$store.dispatch("SAVE_DOCUMENT", {
                    files: this.files
                })
                .then(() => {
                    this.files = [];
                    this.spinnerVisible = false;
                    this.$toast.success(this.$t("message.saved"));
                    this.getFileList();
                })
                .catch(() => this.spinnerVisible = false)
                ;
            })
            .catch(() => this.spinnerVisible = false)
            ;
        },

        callback(progress) {
            console.log('callback');
            console.log(100 * progress.loaded / progress.total);
        },
        getFileList() {
            this.$store.dispatch("LOAD_DOCUMENTS")
            .then(s => this.fileList = s.data)
            ;
        },
        openFile(el) {
            window.open("/api/uicommand/download/file?id=" + el.fileDescriptionId.id, "_blank");
        },
        onRemove(el) {
            if (confirm("Вы действительно хотите удалить файл?")) {
                if (el && el.id) {
                    this.$store.dispatch("DELETE_DOCUMENT", el.id)
                    .then(() => {
                        this.$store.dispatch("DELETE_FILE", el.fileDescriptionId.id);
                        this.getFileList();
                    })
                    ;
                }
            }
        }
    }
}
</script>